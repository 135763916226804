.container{
    height: 120vh;
    position: relative;
 }
 .techTitle{
  width: 100%;
    position: absolute;
    top: -35%;
}

 .card:nth-child(1) {
    top: 30%;   transform: translateY(0px);
  } 
  .card:nth-child(2) {
    top: 40% !important; 
  }  
  .card {
    position: sticky; 
    transform-origin: center top;
     margin-top: 80px;
    height: 380px;
    border-radius: 30px;
    background: none;
    width: 100%;
  }
  .card div{
    height: 100%; 
  }

 
.techCart1 img{
    scale: 1.1;
    transition: 1s; 
}
.techCart2 img{
    scale: 1.1;
    transition: 1s;
}
.techCart3 img{
    scale: 1.1;
    transition: 1s; 
}
.techCart1:hover{
   color:#3E66DF ;
   cursor: pointer;
}
.techCart2:hover{
    color:#3E66DF ;
   cursor: pointer;

 }
.techCart3:hover{
    color:#3E66DF ;
   cursor: pointer;

 }
 .techCart1:hover img{
    scale: 1;
}
.techCart2:hover img{
    scale: 1;
}
.techCart3:hover img{
    scale: 1;
}
.cardUp{
  border-radius: 20px 0 0 20px;
}
.cardDown{
  border-radius: 0 20px 20px 0   ;
}

@media (max-width:1440px){
  .container{
    height: 160vh;
    position: relative;
 }
 .techTitle{
  width: 100%;
    position: absolute;
    top: -35%;
}

 .card:nth-child(1) {
    top: 30%;   transform: translateY(0px);
  } 
  .card:nth-child(2) {
    top: 30% !important; 
  }  
  .card {
    position: sticky; 
    transform-origin: center top;
     margin-top: 80px;
    height: 350px;
    border-radius: 30px;
    background: none;
    width: 100%;
  }
}

@media (max-width:1200px){
  .container{
    height: 160vh;
    position: relative;
 }
 .techTitle{
  width: 100%;
    position: absolute;
    top: -50%;
}

 .card:nth-child(1) {
    top: 30%;   transform: translateY(0px);
  } 
  .card:nth-child(2) {
    top: 30% !important; 
  }  
  .card {
    position: sticky; 
    transform-origin: center top;
     margin-top: 80px;
    height: 380px;
    border-radius: 30px;
    background: none;
    width: 100%;
  }
}

@media(max-width:990px){
  .container{
    height: 250vh;
    position: relative;
 }
 .techTitle{
  width: 100%;
    position: absolute;
    top: -35%;
}

 .card:nth-child(1) {
    top: 30%;   transform: translateY(0px);
  } 
  .card:nth-child(2) {
    top: 30% !important; 
  }  
  .card {
    position: sticky; 
    transform-origin: center top;
     margin-top: 80px;
    height: 450px;
    border-radius: 30px;
    background: none;
    width: 100%;
  }
}

 @media(max-width:770px){
  .container{
    width: 100%;
    height: 250vh;
    position: relative;
 }
 .techTitle{
  width: 100%;
    position: absolute;
    top: -40%;
}

 .card:nth-child(1) {
    top: 30%;   transform: translateY(0px);
  } 
  .card:nth-child(2) {
    top: 30% !important; 
  }  
  .card {
    position: sticky; 
    transform-origin: center top;
     margin-top: 80px;
    height: auto;
    border-radius: 30px;
    background: none;
    width: 100%;
    margin: auto;
  }
  .cardUp{
    border-radius: 20px 20px 0 0;
  }
  .cardDown{
    border-radius: 0 0 20px 20px;
  }
 }

 @media(max-width:670px){
  .container{
    width: 100%;
    height: 300vh;
    position: relative;
 }
 .techTitle{
  font-size: 30px;
  font-weight: 500;
  width: 100%;
    position: absolute;
    top: -25%;
}

 .card:nth-child(1) {
    top: 25%;   transform: translateY(0px);
  } 
  .card:nth-child(2) {
    top: 20% !important; 
  }  
  .card {
    position: sticky; 
    transform-origin: center top;
     margin-top: 80px;
    height: auto;
    border-radius: 30px;
    background: none;
    width: 100%;
    margin: auto;
  }
  .cardUp{
    border-radius: 20px 20px 0 0;
  }
  .cardDown{
    border-radius: 0 0 20px 20px;
  }
 }