.App {
  text-align: center;
  /* overflow-x: hidden; */
}
.popup{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
  z-index: 3;
}
.buttonDiv{
  position: relative;
  transition: 2s;
  height: fit-content;
  width: fit-content;
}
.button { 
  border-radius: 10px;
  background: #33388f;
  color: white;
  border: none;
  padding: 15px 24px;
  font-size: 14px;
  font-weight: 400;
  transition: clip-path .6s ease-out !important;   
  cursor:pointer; 
  transition: 0.3s; 
  height: fit-content;
  width: fit-content;
}

.button-clip { 
  position: absolute;
  top: 0;
  border-radius: 10px;
  background: rgba(26,29, 73);
  color: white;
  border: none;
  padding: 15px 24px;
  font-size: 14px;
  font-weight: 400;  
  color: white;
  clip-path: inset(100% 0 0 0 round 30% 30% 0 0);
  transition: 0.3s;
   height: fit-content;
  width: fit-content;

}
.buttonDiv:hover .button-clip{  
  background: rgba(26,29, 73,1);  
  color: white;
  clip-path: inset(-20% 0 0 0 round 20% 20% 0 0); 
  transition: 0.3s; 
}

.buttonRedDiv{
  position: relative;
  transition: 2s;
  height: fit-content;
  width: fit-content;
}
.buttonRed { 
  border-radius: 10px;
  background: rgb(255, 35, 35);
  color: white;
  border: none;
  padding: 15px 24px;
  font-size: 14px;
  font-weight: 400;
  transition: clip-path .6s ease-out !important;   
  cursor:pointer; 
  transition: 0.3s; 
  height: fit-content;
  width: fit-content;
}

.buttonRed-clip { 
  position: absolute;
  top: 0;
  border-radius: 10px;
  background: rgb(255, 37, 37);
  color: white;
  border: none;
  padding: 15px 24px;
  font-size: 14px;
  font-weight: 400;  
  color: white;
  clip-path: inset(100% 0 0 0 round 30% 30% 0 0);
  transition: 0.3s;
   height: fit-content;
  width: fit-content;

}
.buttonRedDiv:hover .buttonRed-clip{  
  background: rgb(151, 0, 0);  
  color: white;
  clip-path: inset(-20% 0 0 0 round 20% 20% 0 0); 
  transition: 0.3s; 
}

.buttonWhiteDiv{
  position: relative;
  transition: 2s;
  height: fit-content;
  width: fit-content;
}
.buttonWhite { 
  border-radius: 10px;
  /* background:white; */
  color: white;
  border: none;
  padding: 15px 24px;
  font-size: 14px;
  font-weight: 400;
  transition: clip-path .6s ease-out !important;   
  cursor:pointer; 
  transition: 0.3s; 
  height: fit-content;
  width: fit-content;
  border: 0.5px solid white;
}

.buttonWhite-clip { 
  position: absolute;
  top: 0;
  border-radius: 10px;
  background: white;
  color: black;
  border: none;
  padding: 15px 24px;
  font-size: 14px;
  font-weight: 400;  
  color: white;
  clip-path: inset(100% 0 0 0 round 30% 30% 0 0);
  transition: 0.3s;
   height: fit-content;
  width: fit-content;

}
.buttonWhiteDiv:hover .buttonWhite{  
  border: none; 
  transition: 0.3s; 
}
.buttonWhiteDiv:hover .buttonWhite-clip{  
  background: rgba(red, green, blue, 0) ;  
  color: black;
  clip-path: inset(-20% 0 0 0 round 30% 30% 0 0); 
  transition: 0.3s; 
}
 

.hamburger .line{
  width: 35px;
  height: 4px;
  background-color: #333B8F;
  display: block;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
  transition: 1s;
  
}

.hamburger:hover{
  cursor: pointer;
}

#hamburger{
  position: relative;
  right:20px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

#hamburger.is-active{
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger:before{
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  border: 3px solid transparent;
  top: calc(80% - 30px);
  left: calc(75% - 30px);
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger.is-active:before{
  border: 4px solid #333B8F; 

}

#hamburger.is-active .line{
  width: 25px;
  background-color: #333B8F; 
}

#hamburger.is-active .line:nth-child(2){
  opacity: 0;
  background-color: #333B8F;

}

#hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(9px);
  -ms-transform: translateY(9px);
  -o-transform: translateY(9px);
  transform: translateY(9px);
  background-color: #333B8F;

}

#hamburger.is-active .line:nth-child(3){
  -webkit-transform: translateY(-13px) rotate(90deg);
  -ms-transform: translateY(-13px) rotate(90deg);
  -o-transform: translateY(-13px) rotate(90deg);
  transform: translateY(-11px) rotate(90deg);
  background-color: #333B8F;

}
 
.tJustify {
   text-align: justify;
}

.routsLoder{  
  z-index: -1;
  width: 100%; 
  height: 200vh; 
  transition: 3s;
  position: fixed !important;
  top: 0;
}
.routsLoder.active{ 
 z-index: 10;
}
.routsLoder.diactive{ 
  display: none;
}
.routs{
  height: 100%;
  transition: 3s; 
  margin-top: 100%;
}
.routs.activeRouts{
  transition: 3s;  
  margin-top: -150%;
}

@media (max-width: 1220px ){ 
  .routsLoder{  
      z-index: -1;
      width: 100%; 
      height: 140vh; 
      transition: 3s;
      position: fixed !important;
      top: 0;
  }
  .routs{
      height: 150%;
      transition: 3s; 
      margin-top: 100%;
  }
  .routs.activeRouts{
      transition: 3s;  
      margin-top: -200%;
  }
   
} 
 
@media (max-width: 990px ){ 
  .routsLoder{  
      z-index: -1;
      width: 100%; 
      height: 130vh; 
      transition: 3s;
      position: fixed !important;
      top: 0;
  }
  .routs{
      height: 150%;
      transition: 3s; 
      margin-top: 130%;
  }
  .routs.activeRouts{
      transition: 3s;  
      margin-top: -230%;
  } 
} 
 


 
 
 
@media (max-width: 770px ){ 
  .routsLoder{  
      z-index: -1;
      width: 100%; 
      height: 120vh; 
      transition: 3s;
      position: fixed !important;
      top: 0;
  }
  .routs{
      height: 150%;
      transition: 3s; 
      margin-top: 220%;
  }
  .routs.activeRouts{
      transition: 3s;  
      margin-top: -350%;
  }
   
} 