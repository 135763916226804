@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden !important;
  /* background: #0A0B18; */
 
}
p{
  margin: 0;
  padding: 0;
}
*{
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 6px; 
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b9c6eb; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #386aff; 
  border-radius: 15px;
  transition: 2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000547; 
  transition: 2s;

}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white !important;
}
 