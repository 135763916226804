 
.navbarMain{  
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2; 
    height: auto;   
    transition: 300ms; 
    background: transparent;
}
.navbarMain--hidden{
    transform: translateY(-93px);
    transition: 1500ms; 
}
.mainNav{
    border-radius: 19px;
    background: linear-gradient(to right, rgb(207, 207, 207),rgb(238, 238, 238),rgb(240, 240, 240)) ;
}
.navPc{
    display: block;
}
.video{
    width: 600px;
    position: fixed;
    top: 20%;
    right: 5%;
}
.home{ 
    background: linear-gradient(#0A0B18,#1f244d , #0A0B18 , #1f244d); 
    transition: background-color 3s linear; 
}
.homeDr{
    height: 180vh;
}
.dr{
    /* height:200;  */
    width: 100%; 
    position: sticky !important;
    display: flex;
    justify-content: end;
    top: 20%;
    left: 0; 
}
.dr > img {
    height: 80vh;  
}
.circle{
    width: 97%;  
    position: sticky !important;
    display: flex;
    justify-content: end;
    top: 87%;
    left: 0;
    margin-top: -5%;
    z-index: 3;
}
.downCircle{
    animation: App-logo-spin infinite 8s linear;
}
@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .downLogo{
    animation: upDown infinite 1s linear; 
    transition: 1s;
  }

  @keyframes upDown {
    0% {
      margin-top: 0px;
    }
    25% {
        margin-top: -5px;
      }
    50% {
        margin-top: 0px;
    }
    75% {
        margin-top: 5px;
    }
    100% {
        margin-top: 0px;
    }
  } 
 
/* .color-home1{ 
    background-image:url('../images/bg.jpg');
    background-size: 100% 100%;
    transition: background-image 3s;

}
.color-home2{ 
    background-image:url('../images/bg.jpg'); 
    background-size: 100% 100%; 
    transition: background-image 3s;

} */

.navMobile{
    display: none;
}
.popele1{
    width: 100%;
    height: 600px;
}
.popele2{
    width: 100%;
    height: 500px;
}
.popele2 > img{
    width: 100%;
    height: 100%;
}
.popeleMan{
    width: 110px;
    top: 10%;

}
/* .tech{
    height: 200vh;
    position: relative;
}
.techTitle{
    position: sticky;
    top: 50px;
}
.techCart1{
    position: sticky;
    top: 150px; 
   width: 80%;
    margin: auto;
}
.techCart2{
    position: sticky;
    top: 220px; 
    width: 90%;
    margin: auto;


}
.techCart3{
    position: sticky;
    top: 300px; 
     width: 100%;
    margin: auto; 
}
.techCart1 img{
    scale: 1.1;
    transition: 1s; 
}
.techCart2 img{
    scale: 1.1;
    transition: 1s;
}
.techCart3 img{
    scale: 1.1;
    transition: 1s; 
}
.techCart1:hover{
   color:#3E66DF ;
   cursor: pointer;
}
.techCart2:hover{
    color:#3E66DF ;
   cursor: pointer;

 }
.techCart3:hover{
    color:#3E66DF ;
   cursor: pointer;

 }
 .techCart1:hover img{
    scale: 1;
}
.techCart2:hover img{
    scale: 1;
}
.techCart3:hover img{
    scale: 1;
} */

.testimonials{
    background: linear-gradient(#fff 50%,#000000 0);
 }
 .testimonialsCard{
    position: relative; 
    margin: auto;
    display: flex;
 width: 320px;
 /* padding: 15px 18.75px 16px 82.5px; */
 padding: 15px 18.75px 16px 25.5px;
 
 flex-direction: column;
 justify-content: center;
 align-items: flex-start;
 gap: 13.125px; 
 border-radius: 12px;
 background: #FFF;
 
 }
  .testimonialsPost{
 color: #505050;
 font-size: 10px;
 font-family: Manrope;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
  }
 .testimonialsImg{
width: 80px;
 height: 80px;
 position: absolute;
 left: 20px;
 top: -40px;
 border-radius:50%;
 }
 
 .techPc{
    display: block;
 }
 .techMb{
    display: none;
 }
 
 .uperFooterCircle {
    position: relative;
    border-radius: 200px; 
    backdrop-filter: blur(10px); 
    box-shadow: inset 0 0 0 200px rgba(255,255,255,0.05);  
 }
 .uperFooterCircle::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 200px; 
    padding: 3px; 
    background:linear-gradient(white,#1E2046); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;  
            box-shadow: inset 0 0 0 200px rgba(255,255,255,0.05); 
  }

  
.advantagesPc{
 display: block;
 height: 100vh;
 display: flex;
 align-items: center;
 justify-content: center;
}
.advantagesMb{
display: none; 
}
.whyArthoText{
    font-size: 200px;
}
.titleText {
    font-size:140px;
    font-weight: 400;
}
.clpiText{
    font-size: 100px;
    margin-bottom: 0; 
    text-align: start;
    font-weight: 500;
}
.wordColor1{ 
    width: 100%;
}
.scroll-to-top{
    background: #333B8F !important;
    border-radius: 50% !important;
    color: white !important;
}
.scroll-to-top svg{
    fill : white !important; 
}
.artroMrImg{
    scale: 0.9;
    transition: 2s;
    transform: rotate(20deg); 
}
.artroMr:hover .artroMrImg{
    scale: 1.1; 
    transform: rotate(0deg); 
}
.artroMrPlanImg{
    scale: 1.3;
    transition: 2s;
    transform: rotate(-20deg);
}
.artroMrPlan:hover .artroMrPlanImg{
    scale: 1.5;  
    transform: rotate(0deg);

}
.imgScal{
scale: 0.9;
transition: 1s;
} 
.imgScal:hover {
scale: 1;
} 
.arthroPlanText{
    font-size: 400px;
    color: #131427;
    margin-bottom: 0%; 
}
.arthoPlanDiv{
    height: 100vh;
}

.swift-up-text > span {
    display: inline-block;
    animation: fadeInUp 0.5s ease;
    animation-fill-mode: both;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .form-control{ 
      border: 0.5px solid #3B3D50;
      color:#bfc5ff ;
  } 
  .form-control:focus {
    border-color: white;
    color:#bfc5ff ;

    
  }
  .form-lable{ 
    color:   #bfc5ff;
    }  
    

    ::placeholder {
        color: #bfc5ff;
        opacity: 1; /* Firefox */
      }
      
      ::-ms-input-placeholder { /* Edge 12-18 */
        color: #bfc5ff;
      }
      input {
        color-scheme: red;
      }
 


@media(max-width:1620px){
    
.homeDr{
    height: 160vh;
}
.dr{
    /* height:200;  */
    width: 100%; 
    position: sticky !important;
    display: flex;
    justify-content: end;
    top: 20%;
    left: 0; 
}
.dr > img {
    height: 80vh;  
}
    .titleText {
    font-size:120px;
    font-weight: 400;

    }
    .clpiText{
        font-size: 65px;
        margin-bottom: 0; 
        text-align: start;
    font-weight: 500;

    }
    .video{
        width: 500px;
        position: fixed;
        top: 20%;
        right: 5%;
    }
    .whyArthoText{
        font-size: 150px;
      }
      .popele1{
        width: 100%;
        height: 500px;
    }
    .popele2{
        width: 100%;
        height: 400px;
    }
    .popele2 > img{
        width: 100%;
        height: 100%;
    }
    .popeleMan{
        width: 100px;
        top: 10%;
    
    }
    .arthroPlanText{
        font-size: 300px;
        color: #131427;
        margin-bottom: 0%;
        
    }
}

 
@media(max-width:1440px){
    
.homeDr{
    height: 190vh;
}
.dr{ 
    width: 100%; 
    position: sticky !important;
    display: flex;
    justify-content: end;
    top: 20%;
    left: 0; 
}
.dr > img {
    height: 80vh;  
}
    .titleText {
        font-size:100px;
    font-weight: 400;

    }
}
 
@media(max-width:1220px){
    
.homeDr{
    height: 185vh;
}
.dr{
    /* height:200;  */
    width: 100%; 
    position: sticky !important;
    display: flex;
    justify-content: end;
    top: 25%;
    left: 0; 
}
.dr > img {
    height: 75vh;  
}
    .titleText {
        font-size:80px;
    font-weight: 400;

    }
    .clpiText{
        font-size: 60px;
        margin-bottom: 0;
        line-height: 100px;
        text-align: start;
    font-weight: 500;

    } 
    .whyArthoText{
        font-size: 120px;
      }
      .video{
        width: 450px;
        position: fixed;
        top: 20%;
        right: 5%;
    }
    .popele1{
        width: 100%;
        height: 500px;
    }
    .popele2{
        width: 100%;
        height: 400px;
    }
    .popele2 > img{
        width: 100%;
        height: 100%;
    }
    .popeleMan {
        width: 100px;
        top: 8%;

    }
    .arthroPlanText{
        font-size: 250px;
        color: #131427;
        margin-bottom: 5%;
        
    }
}

@media(max-width:990px){
    
.homeDr{
    height: 180vh;
}
.dr{
    /* height:200;  */
    width: 100%; 
    position: sticky !important;
    display: flex;
    justify-content: end;
    top: 30%;
    left: 0; 
}
.dr > img {
    height: 70vh;  
}
    .navPc{
        display: none;
    }
    
    .navMobile{
        display: block;
    }
    .video{
        width: 400px;
        position: fixed;
        top: 20%;
        right: 5%;
    } 
    .whyArthoText{
        font-size: 95px;
    }

    .popele1{
        width: 100%;
        height: 450px;
    }
    .popele2{
        width: 100%;
        height: 350px;
    }
    .popele2 > img{
        width: 100%;
        height: 100%;
    }
    .popeleMan {
        width: 100px;
        top: 8%;
    }
    .titleText {
        font-size:70px;
    font-weight: 400;

    }
    .clpiText{
        font-size: 50px;
        margin-bottom: 0;
        line-height: 95px;
    font-weight: 500;

    }
    .arthroPlanText{
        font-size: 200px;
        color: #131427;
        margin-bottom: 5%;
        
    }
    
}

@media(max-width:770px){
    
.homeDr{
    height: 145vh;
}
.dr{
    /* height:200;  */
    width: 100%; 
    position: sticky !important;
    display: flex;
    justify-content: end;
    top: 20%;
    left: 0; 
}
.dr > img {
    height: 80vh;  
    display: none;
}
    .video{
        width: 70%;
        position: fixed;
        top: 10%;
        right: 15%;  
        transition: 1s;
    } 
    .whyArthoText{
        font-size: 80px;
    }
     .popele{
        margin-top: 100px;
     }
     .popele1{
        width: 100%;
        height: 400px;
    }
    .popele2{
        width: 100%;
        height: 300px;
    }
    .popele2 > img{
        width: 100%;
        height: 100%;
    }
    .popeleMan {
        width: 90px;
        top: 9%;
    }
    .techPc{
        display: none;
     }
     .techMb{
        display: block;
     }
    .advantagesPc{
        display: none;
    }
    .advantagesMb{
        display: block;
    }
    .titleText {
        font-size:65px;
    font-weight: 400;

    }
    .clpiText{
        font-size: 50px;
        margin-bottom: 0;
        line-height: 75px;
    font-weight: 500;

    }
    .arthroPlanText{
        font-size: 130px;
        color: #131427;
        margin-bottom:5%;
        
    }
    .arthoPlanDiv{
        height: 120vh;
    }
}


@media(max-width:560px){ 
    .homeDr{
        height: 135vh;
    }
    .circle{
        width: 100%;  
        position: absolute !important;
        display: flex;
        justify-content: center;
        top: 65%;
        left: 0%;
    }
    .titleText {
        font-size:53px;
    font-weight: 400;

    }
    .whyArthoText{
        font-size: 55px;
    }
   
   .clpiText{
       font-size: 36px;
       margin-bottom: 0;
       line-height: 60px;
    font-weight: 500;

   }

   .popele{
    margin-top: 100px;
 }
 .popele1{
    width: 100%;
    height: 250px;
}
.popele2{
    width: 100%;
    height: 200px;
}
.popele2 > img{
    width: 100%;
    height: 100%;
}
.popeleMan {
    width: 60px;
    top: 5%;
}
.arthroPlanText{
    font-size: 80px;
    color: #131427;
    margin-bottom: 0%;
    
}
.arthoPlanDiv{
    height: 90vh;
}
}
 